<template>
  <!-- Sound files table -->
  <div class="bg-white w-full p-3">
    <section class="w-full h-full">
      <PageHeader
        :heading="$t('xpbx.settings.sound_files.heading')"
        :showButton="false"
      />

      <div class="card relative table-wrapper">
        <!-- Table -->
        <DataTable
          ref="dt"
          selectionMode="single"
          dataKey="id"
          :paginator="true"
          :rows="10"
          scrollable
          removableSort
          :scrollHeight="`${scrollHeight}px`"
          v-model:selection="selectedRecords"
          v-model:filters="filters"
          :value="soundFiles"
          filterDisplay="menu"
          paginatorPosition="top"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} sound files"
          :rowsPerPageOptions="[5, 10, 25]"
          v-if="!loading"
          :globalFilterFields="['filename', 'description']"
        >
          <template #empty>
            {{ $t("xpbx.settings.fields.empty_table") }}</template
          >
          <template #header>
            <div class="flex gap-2 align-items-center justify-between">
              <div>
                <Button
                  class="mr-2 add-record-button"
                  @click="openNew"
                  :label="$t('xpbx.settings.sound_files.buttons.new')"
                />
              </div>
              <div class="flex gap-2 items-center">
                <InputText
                  class="search-input"
                  v-model="filters['global'].value"
                  :placeholder="
                    $t('xpbx.settings.sound_files.placeholders.search')
                  "
                />
                <Export
                  :dt="dt"
                  :tableData="soundFiles"
                  :columns="soundFilesHeaders"
                />
              </div>
            </div>
          </template>
          <Column
            sortable
            field="filename"
            style="width: 20%"
            :header="$t('xpbx.settings.sound_files.fields.name')"
          >
            <template #body="{ data }">
              <div class="relative inline-block">
                {{ data?.filename }}
                <div class="flex items-center gap-2">
                  <AudioPlayer :id="data.id" />

                  <TableActions
                    :data="data"
                    :id="data.id"
                    :showIsActive="false"
                    @edit="editHandle"
                    @delete="deleteSelectedRecord"
                  />
                </div>
              </div>
            </template>
          </Column>
          <Column
            sortable
            field="description"
            style="width: 20%"
            :header="$t('xpbx.settings.sound_files.fields.description')"
          >
            <template #body="{ data }">
              {{ data?.description }}
            </template>
          </Column>
          <Column
            sortable
            field="filetype"
            style="width: 20%"
            :header="$t('xpbx.settings.sound_files.fields.filetype')"
          >
            <template #body="{ data }">
              {{ getFileType(data?.filetype) }}
            </template>
          </Column>
          <Column
            sortable
            field="filesize"
            style="width: 20%"
            :header="$t('xpbx.settings.sound_files.fields.filesize')"
          >
            <template #body="{ data }">
              {{ data?.filesize }}
            </template>
          </Column>
          <Column
            sortable
            field="created_at"
            style="width: 20%"
            :header="$t('xpbx.settings.sound_files.fields.created_at')"
          >
            <template #body="{ data }">
              <DateItem :value="data?.created_at" />
            </template>
          </Column>
        </DataTable>
        <Loader v-else />
      </div>

      <!-- Dialogs -->
      <Dialog
        v-model:visible="deleteRecordsDialog"
        :style="{ width: '450px' }"
        header="Confirm"
        :modal="true"
        class="p-fluid relative"
      >
        <div class="confirmation-content">
          <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
          <span v-if="selectedRecord?.description">{{
            $t("xpbx.settings.sound_files.notification.confirm_delete", {
              delete: `sound file ${selectedRecord?.description}`,
            })
          }}</span>
        </div>
        <template #footer>
          <Button
            text
            @click="deleteRecordsDialog = false"
            :label="$t('xpbx.settings.sound_files.buttons.no')"
          />
          <Button
            text
            @click="deleteSelectedRecords"
            :label="$t('xpbx.settings.sound_files.buttons.yes')"
          />
        </template>
      </Dialog>

      <!-- Create template -->
      <Dialog
        v-model:visible="recordDialog"
        :style="{ width: '500px' }"
        :header="
          isEdit
            ? $t('xpbx.settings.sound_files.dialogs.edit_sound_file')
            : $t('xpbx.settings.sound_files.dialogs.create_sound_file')
        "
        :modal="true"
        :dismissableMask="true"
        class="p-fluid relative custom-dialog-heading"
      >
        <!-- Description -->
        <FormInput
          id="description"
          :error="errors?.description?.[0]"
          v-model:modelValue="soundFile.description"
          :label="$t('xpbx.settings.sound_files.labels.description')"
          :hint="$t('xpbx.settings.sound_files.hints.description')"
        />

        <!-- Filetype -->
        <FormDropdown
          optionLabel="name"
          :options="fileTypeOptions"
          :error="errors?.filetype?.[0]"
          v-model:modelValue="soundFile.filetype"
          :label="$t('xpbx.settings.sound_files.labels.filetype')"
          :hint="$t('xpbx.settings.sound_files.hints.filetype')"
        />

        <!-- Name -->
        <div class="field mb-1 mt-2" v-if="soundFile?.filename">
          <div class="mb-2">
            <label class="mr-2">{{
              $t("xpbx.settings.sound_files.placeholders.filename")
            }}</label>
            <span>{{ soundFile.filename }}</span>
          </div>
          <div v-if="audioFileSize">
            <label class="mr-2">{{
              $t("xpbx.settings.sound_files.placeholders.size")
            }}</label>
            <span>{{ audioFileSize }}</span>
          </div>
        </div>

        <!-- File -->
        <div class="flex items-center column-gap-4 mb-1">
          <FileUpload
            mode="basic"
            name="demo[]"
            url="/api/upload"
            accept="audio/*"
            :maxFileSize="5000000"
            customUpload
            :auto="true"
            @uploader="customUpload"
          />
          <!-- Player on Edit Screen -->
          <div
            v-if="isEdit && soundFile?.id && !audioFile"
            class="ml-4 cursor-pointer"
          >
            <FullAudioPlayer :id="soundFile.id" />
          </div>
          <audio controls v-if="audioFile" class="audio-player ml-4">
            <source src="" type="audio/mpeg" ref="audioPlayer" />
            Your browser does not support the audio element.
          </audio>
        </div>
        <small class="p-error" v-if="submitted && !audioFile && !isEdit">{{
          $t("xpbx.settings.sound_files.errors.file_required")
        }}</small>
        <QueueHint :title="$t('xpbx.settings.sound_files.hints.file')" />

        <template #footer>
          <DialogButtons
            :saveButtonText="$t('xpbx.button.save')"
            :cancelButtonText="$t('xpbx.button.cancel')"
            @save="create"
            @cancel="recordDialog = false"
          />
        </template>
      </Dialog>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, watch, inject } from "vue";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import useSoundFiles from "@/modules/xpbx/composables/useSoundFiles";
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";
import DateItem from "@/modules/xpbx/components/UI/DateItem/DateItem.vue";
// Datatable
import Column from "primevue/column";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import DataTable from "primevue/datatable";
import Textarea from "primevue/textarea";
import AudioPlayer from "./AudioPlayer.vue";
import FullAudioPlayer from "./FullAudioPlayer.vue";
import FileUpload from "primevue/fileupload";
import { FilterMatchMode } from "primevue/api";
import validator from "@/composables/auth/validator";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import Export from "@/modules/xpbx/pages/settings/components/Export/Export.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import { validateSoundFile } from "@/composables/auth/templateValidations";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import { soundFilesHeaders } from "@/modules/xpbx/pages/settings/components/table-headers/data.js";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "SoundFiles",

  components: {
    PageHeader,
    Loader,
    DataTable,
    Column,
    Button,
    InputText,
    Export,
    Dialog,
    Textarea,
    DateItem,
    FormDropdown,
    FileUpload,
    AudioPlayer,
    FullAudioPlayer,
    QueueHint,
    FormInput,
    TableActions,
    DialogButtons,
  },

  setup() {
    // Datatable
    const dt = ref();
    const t = inject("t");
    const isEdit = ref(false);
    const submitted = ref(false);
    const { errors } = validator();
    const recordDialog = ref(false);
    const audioPlayer = ref(null);
    const selectedRecord = ref(null);
    const audioBlob = ref(null);
    const selectedRecords = ref([]);
    const deleteRecordsDialog = ref(false);
    const scrollHeight = ref(window.innerHeight - 290);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const audioFile = ref(null);
    const audioFileSize = ref(null);
    const selectText = t("xpbx.settings.dropdown.select_option");

    const {
      findSoundFIle,
      findSoundFIles,
      createSoundFIle,
      updateSoundFIle,
      deleteSoundFIle,
      loading,
      soundFile,
      soundFiles,
    } = useSoundFiles();

    const editHandle = (data) => {
      const fileType = fileTypeOptions.find(
        (option) => option.value === data.filetype
      );

      const form = {
        ...data,
        filetype: fileType || {
          name: selectText,
          value: null,
        },
      };
      soundFile.value = form;
      selectedRecord.value = form;
      audioFileSize.value = data?.filesize;

      isEdit.value = true;
      recordDialog.value = true;
    };

    // Data Table Methods
    const openNew = () => {
      reset();
      isEdit.value = false;
      recordDialog.value = true;
    };

    const customUpload = async (event) => {
      const file = event.files[0];

      audioFile.value = file;
      audioFileSize.value = file.size;
      soundFile.value.filename = file.name;

      setTimeout(() => {
        const audioPlayerElement = document.querySelector(".audio-player");

        audioPlayerElement.src = URL.createObjectURL(file);
      }, 300);
    };

    const reset = () => {
      soundFile.value = {
        filename: "",
        description: "",
        // filetype: "ivr",
        filetype: {
          name: selectText,
          value: null,
        },
        audio: null,
      };
      audioFile.value = null;
      submitted.value = false;
      audioFileSize.value = null;
      selectedRecord.value = null;
    };

    const closeDialog = (value) => {
      recordDialog.value = value;
    };

    const updateSound = async () => {
      const formData = new FormData();

      if (audioFile.value) formData.append("audio", audioFile.value);
      formData.append("filename", soundFile.value?.filename);
      formData.append("filetype", soundFile.value?.filetype || null);
      formData.append("description", soundFile.value?.description);

      await updateSoundFIle(formData, selectedRecord.value.id);
      isEdit.value = false;
      recordDialog.value = false;
    };

    const createSound = async () => {
      if (!audioFile.value) return;

      let fileType = null;
      if (soundFile.value.filetype?.name === selectText) {
        fileType = null;
      } else {
        fileType = soundFile.value.filetype;
      }

      const formData = new FormData();
      formData.append("audio", audioFile.value);
      formData.append("filename", soundFile.value.filename);
      formData.append("filetype", fileType);
      formData.append("description", soundFile.value.description);

      await createSoundFIle(formData);
      recordDialog.value = false;
      submitted.value = false;
    };

    const create = async () => {
      submitted.value = true;
      const isValid = validateSoundFile(soundFile.value);

      if (isValid) {
        if (isEdit.value === true) {
          await updateSound();
          submitted.value = false;
        } else {
          await createSound();
        }
      }
    };

    const confirmDeleteSelected = () => {
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      deleteRecordsDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      await deleteSoundFIle(selectedRecord.value.id);
      deleteRecordsDialog.value = false;
    };

    const fileTypeOptions = [
      { name: t("xpbx.settings.sound_files.options.ivr"), value: "ivr" },
      { name: t("xpbx.settings.sound_files.options.moh"), value: "moh" },
    ];

    const getFileType = (type) => {
      if (!type || type === "null") return "";
      return t(`xpbx.settings.sound_files.translations.${type}`);
    };

    watch(recordDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findSoundFIles();
    });

    return {
      dt,
      audioBlob,
      filters,
      isEdit,
      errors,
      loading,
      soundFile,
      selectText,
      soundFiles,
      audioFile,
      audioFileSize,
      recordDialog,
      soundFilesHeaders,
      submitted,
      audioPlayer,
      scrollHeight,
      selectedRecord,
      selectedRecords,
      fileTypeOptions,
      deleteRecordsDialog,
      create,
      reset,
      openNew,
      getFileType,
      findSoundFIle,
      findSoundFIles,
      createSoundFIle,
      updateSoundFIle,
      deleteSoundFIle,
      closeDialog,
      customUpload,
      editHandle,
      confirmDeleteSelected,
      deleteSelectedRecord,
      deleteSelectedRecords,
    };
  },
};
</script>


<style lang="scss" scoped>
.p-button.add-record-button {
  background: rgba(#32bcad, 0.8);
  border: 1px solid rgba(#32bcad, 0.8);

  &:hover {
    background: rgba(#32bcad, 1);
    border: 1px solid #32bcad;
  }
}

.p-button.delete-button {
  background: #f05a94;
  border: 1px solid #f05a94;

  &:hover {
    background: #f05a94;
  }
}

.p-button.delete-button.p-disabled {
  background: rgba(#f05a94, 0.5);
  border: 1px solid rgba(#f05a94, 0.5);
  border-color: rgba(#f05a94, 0.5);

  &:hover {
    background: rgba(#f05a94, 0.8);
    border-color: rgba(#f05a94, 0.8);
  }
}
</style>